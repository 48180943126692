@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.createFolderModal {
  .modalBody {
    padding: 0 0 20px;

    @include mqMaxWidth($screenS) {
      padding: 0 0 10px;
    }
  }

  :global(.modalDialog) {
    max-width: 600px;
  }
}

.spinnerOverlay {
  top: 53px;
  bottom: 56px;
}
