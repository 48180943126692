@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.limitsWrapper {
  padding: 10px;
  background: #f2fff2;
  text-align: center;

  &.disabled {
    background: $disclaimerColor;
  }
}
