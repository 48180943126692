@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.container {
  display: inline-flex;
  align-items: center;

  @include mqMaxWidth($screenS) {
    justify-content: center;
  }

  .spinner {
    margin-left: 10px;

    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}
