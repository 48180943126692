@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.nodeItem {
  border-bottom: 1px solid #f0f0f0;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  padding: 0 20px;

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.nodeFolderIcon {
  position: relative;
  top: 3px;
}

.nodeItem.isDisabled {
  cursor: default;

  .nodeFolderIcon svg {
    fill: $secondary2Color;
  }

  .nodeItemTitle {
    color: $secondaryColor;
  }
}

.nodeItemTitle {
  padding: 6px 0;
  color: $titleColor;
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
}

.rootNodeItem {
  border-top: 1px solid #f0f0f0;
}

.toggleIcon {
  position: absolute;
  top: 9px;
  margin-left: -6px;

  @include mqMaxWidth($screenS) {
    margin-left: -16px;
  }
}

.isSelectable:hover {
  background: #f9f9f9;
}

.isSelected {
  &:hover {
    background: #fff7de;
  }

  background: #fff7de;
}
