@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.copiedNoText {
  border: 1px solid $secondaryColor;
  border-radius: 3px;
}

.iconOutline {
  margin: 0 30px;
  fill: $actionColor;
  top: 2px;
}
