@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contentWrp {
  position: relative;
}

.disclaimer {
  padding: 1px 15px;
  background: $backgroundHighlightColor;
  margin-bottom: 30px;

  @include mqMaxWidth($screenS) {
    margin: 0 10px 10px;
  }
}

.disclaimerBold {
  font-weight: 500;
}

.disclaimerUnderline {
  text-decoration: underline;
}

.overrideShiftNonPinnedButton {
  background-color: $successColor;

  &:hover {
    background-color: $successColorHover;
  }
}

.actionButtons,
.leftButtonWrp {
  display: inline-block;
}

.buttonsWrp {
  @include mqMaxWidth(540px) {
    .actionButtons {
      display: block;
      margin-bottom: 10px;

      > button:last-child {
        margin-right: 0;
      }
    }
  }

  @include mqMaxWidth(430px) {
    .leftButtonWrp {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
