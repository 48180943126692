@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.searchWrp {
  padding: 0 10px;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.overflowContainer {
  overflow: auto;
  position: absolute;
  bottom: 0;
  top: 66px;
  left: 0;
  right: 0;

  &.withSmallSearch {
    top: 134px;
  }

  &.withMediumSearch {
    top: 199px;
  }

  &.withLargeSearch {
    top: 233px;
  }

  &.withExtraLargeSearch {
    top: 297px;
  }

  @include mqMaxWidth($screenS) {
    position: static;
    overflow: visible;
  }
}

.navigationContent {
  display: inline-block;
  min-width: 100%;
}

.fileIndexItem,
.redactionItem,
.downloadsItem {
  margin-bottom: 15px;
}

.folderTree {
  padding: 15px 0 0 20px;
  display: inline-block;

  @include mqMaxWidth($screenS) {
    padding: 20px 0 0 10px;
  }
}

.isDragging {
  pointer-events: none;
}
