@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contentWrp {
  position: relative;

  @include mqMaxWidth($screenS) {
    padding: 0 10px 10px;
  }
}

.disclaimer {
  padding: 15px;
  background: $backgroundHighlightColor;
  margin-bottom: 30px;
}

.indexWrp {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.delimiter {
  color: #000;
  width: 24px;
  height: 36px;
  padding: 9px 11px;
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
  }
}

.indexValue {
  display: inline-block;
  padding: 8px 17px;
  background: $btnDefaultColor;
  border: 1px solid $btnDefaultColorHover;
  border-radius: 3px;
  color: $secondary3Color;
  margin-bottom: 30px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
  }
}

.stagingPrefix {
  margin-right: 10px;

  &:after {
    content: 'S';
  }
}

.currentIndexControl {
  display: inline-block;
  margin-bottom: 30px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
  }
}

.currentIndex {
  position: static;

  > input {
    min-width: 44px;
    width: 44px;
    text-align: center;
  }

  > div {
    position: absolute;
    left: 0;
    white-space: nowrap;
  }
}

.withSpace {
  margin-right: 20px;
}

.explanation {
  display: flex;
  white-space: nowrap;
  padding: 8px 0;
  flex: 1;
  min-width: 200px;
}

.nameWrp {
  margin-left: 5px;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nameContainer {
  width: 100%;
}

.name {
  color: $titleColor;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}

.buttonsWrp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  button:last-child {
    margin-right: 0;
  }

  @include mqMaxWidth($screenXs) {
    &.isFileIndexCustom {
      flex-direction: column;
    }

    &.isFileIndexCustom > *:first-child {
      margin-bottom: 10px;
    }
  }
}

.unpinButton {
  background-color: $successColor;

  &:hover {
    background-color: $successColorHover;
  }
}
