@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.exportTimeoutModal {
  :global(.modalDialog) {
    max-width: 600px;
  }
}

.contentWrp {
  position: relative;

  > p:first-child {
    margin-top: 0;
  }

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}
