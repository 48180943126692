@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contentWrp {
  position: relative;
  padding: 80px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundHighlightColor;

  .inputWrp {
    > div {
      margin-bottom: 0;
    }
  }

  &.permissionsSectionVisible {
    padding-top: 20px;
    padding-bottom: 0;
  }

  @include mqMaxWidth($screenS) {
    padding: 30px 10px 0;
    margin: 0 10px;
  }
}

.footer {
  margin-top: 20px;
}

.tooltipContainer {
  float: right;
}
