@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.formWrapper {
  width: 100%;
  text-align: center;
  margin: 30px auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputWrapper {
  margin: 0;
  padding-bottom: 30px;
  width: 100%;
  max-width: 275px;
  position: relative;
}

.formInput {
  text-align: center;
  color: $secondaryColorHover;
  letter-spacing: 5px;
}

.formError {
  color: $cancelColor;
  display: block;
  position: absolute;
  width: 100%;
  top: 38px;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
}

.formInputError {
  border-color: $cancelColor;
  &:focus {
    border-color: $cancelColor;
  }
}

.resendWrapper {
  display: flex;
  justify-content: center;
}

.resendButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: $actionColor;
}

.formIcon {
  margin-left: 4px;
  width: 12px;
  height: 12px;
  fill: $actionColor;
}

.formContact {
  font-size: 12px;
  line-height: 14px;
  color: $secondary3Color;
  max-width: 275px;
  margin: 20px auto 0;
}
