@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.group {
  border-bottom: 1px solid #f3f3f3;
  padding: 6px 0 !important;
}

.groupHeader {
  font-size: 10px !important;
  line-height: 7px !important;
  padding: 8px 10px 6px !important;
  cursor: pointer !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option {
  font-size: 14px !important;
  line-height: 20px;
  padding: 6px 10px 6px 17px !important;
}

.selectedOption {
  [class$="-option"] {
    &:after {
      content: "";
      width: 6px !important;
      height: 10px !important;
    }
  }
}

.multiValueWrp {
  max-width: 100%;
}
