@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.footerPortalWrp {
  transform: translate3d(0, 0, 0);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

.applicationWrp {
  background-color: #f2f2f2;
}

.overlay {
  background-color: transparent;
}

.layoutWrp {
  height: 100%;
  display: flex;

  @include mqMaxWidth($screenS) {
    max-width: 100vw;
    padding-top: 50px;
  }
}

div.pageHeader {
  margin-bottom: 11px;

  .pageHeaderTitle {
    word-break: break-all;
  }

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.contentCardWrp {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;
  flex-grow: 1;
}

.dialInsSection {
  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.spinnerOverlay {
  @include mqMaxWidth($screenS) {
    position: absolute;
    top: 28px;
    z-index: 1;

    .spinner {
      position: fixed;
      margin-top: 29px;
    }
  }
}

.partlySpinnerOverlay {
  display: none;
  z-index: 0;

  @include mqMaxWidth($screenS) {
    display: block;
  }
}
