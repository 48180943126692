@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.askQuestionButton {
  width: 100%;

  &[disabled] {
    pointer-events: none;
  }
}

.tooltipWrp {
  width: 100%;
}

.tooltip {
  margin: 5px 0;
}

.emailAlertsButton {
  font-size: 12px;
  line-height: 18px;
  white-space: break-spaces;
  text-align: left;

  height: auto;
  padding: 0;
  margin-bottom: 20px;
}

.emailAlertsIcon {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 10px;

  height: 12px;
  width: 12px;

  svg {
    fill: $actionColor;
  }
}
