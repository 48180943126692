@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.searchWrp {
  padding: 10px;
  border-radius: 3px;

  &:hover {
    background: $backgroundHighlightColor;
  }

  &.isOpened {
    background: $backgroundHighlightColor;
  }

  &.isMobile {
    padding: 0;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
}

.optionsWrp {
  margin-top: -10px;
}

.footerButtons {
  display: flex;
}

.searchBtn {
  flex: 1;

  .searchTitleWrp {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBtnIcon {
    margin-right: 10px;
  }

  svg {
    margin-right: 10px;

    fill: #fff;
  }
}

.cancelBtn {
  flex: 1;
  margin-right: 0;
}
