@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.modal {
  :global(.modalDialog) {
    max-width: 600px;
  }
}

.modalBody {
  padding-top: 10px;
}

.container {
  margin-bottom: 20px;

  .wrapper {
    margin-bottom: 8px;
  }

  .label {
    display: inline-block;
    width: 100px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 7px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $secondaryColor;
  }

  .name {
    color: $titleColor;
  }
}

.description {
  padding: 20px;
  margin: 0;
  line-height: 20px;
  letter-spacing: 0.01em;
  background: $disclaimerColor;
}
