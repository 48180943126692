@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.overlayContainer {
  position: absolute;
  left: 200px;
  display: flex;
  width: max-content;
  min-width: 150px;
  align-items: center;
  justify-content: space-around;
  gap: 5px 10px;
  border: 1px solid $gray2;
  padding: 6px 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: $yellow;
  font-size: 14px;
  line-height: 16px;
  cursor: grabbing;

  &:before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: -25px;
    width: 50px;
    height: 50px;
  }
}

.explorerItem {
  display: flex;
  gap: 10px;
  align-items: center;
  color: $black2;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
}

.filesystemIcon,
.filesystemIcon i {
  width: 14px;
  height: 14px;
}

.overlayDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
}

.counter {
  background: $red2;
  padding: 1px 3px;
  border-radius: 2px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
}

.counterAbsolute {
  position: absolute;
  left: -13px;
  top: -13px;
}

.notAllowed {
  cursor: not-allowed;
}
