@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.bookmarkActionButton {
  svg {
    fill: none !important;
    stroke: $actionColor;
  }

  &:hover svg {
    stroke: $actionColorHover;
  }

  .bookmarkButton {
    width: 100%;
  }
}

.isBookmarked {
  svg {
    fill: $actionColor !important;
  }

  &:hover svg {
    fill: $actionColorHover !important;
  }
}
