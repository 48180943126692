@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.subTitleSection {
  display: flex;
  padding: 10px 20px;
  background-color: $backgroundHighlightColor;
  margin-bottom: 20px;
}

.subTitle {
  font-weight: 500;
}

.folderIcon {
  margin-right: 10px;
  fill: #ffad0f;
}

.textSection {
  margin-bottom: 20px;
}

.formSection {
  display: flex;
  justify-content: space-between;

  > div {
    flex-grow: 1;
    max-width: calc(50% - 5px);
  }

  .bigInput {
    max-width: none;
    margin-right: 10px;

    + div {
      flex-grow: 0;

      input {
        min-width: 115px;
        width: 115px;
      }
    }
  }
}

.title {
  font-size: 20px;
  line-height: 33px;
  margin-bottom: 20px;
}

.button:last-of-type {
  width: 100%;
  margin-right: 0;
}

.termsOfUse {
  text-align: center;
  margin-top: 30px;
}

.recaptchaWrapper {
  text-align: center;
}
