@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.inlineFieldWrp {
  display: flex;

  @include mqMaxWidth($screenS) {
    flex-direction: column;
  }
}

.formLabel {
  min-width: 110px;
  vertical-align: top;
  margin-top: 10px;

  @include mqMaxWidth($screenS) {
    margin-top: 0;
  }
}

.formField {
  width: 285px;
}
