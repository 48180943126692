@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.editModal {
  :global {
    .modalDialog {
      max-width: 600px;
    }

    .modalBody {
      padding-top: 10px;

      table tbody {
        max-height: none;
      }

      @include mqMaxWidth($screenS) {
        padding-top: 0;
      }
    }

    .modalHeader {
      @include mqMaxWidth($screenS) {
        padding-bottom: 5px;
      }
    }
  }
}
