@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.uploadDoneWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  button {
    margin-right: 0;
  }
}

.icon {
  height: 60px;
  width: 68px;
  fill: #c0c0c0;
  margin-bottom: 20px;
}

.title {
  color: $successColor;
  margin-bottom: 20px;
}

.subtitle {
  max-width: 320px;
  margin-bottom: 20px;
  text-align: center;
}

.subtitleError {
  color: $cancelColor;
}

.exportBtn {
  background: none;
}

.textBold {
  font-weight: bold;
}
