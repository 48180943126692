@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.sectionWrp {
  padding-top: 20px;
  margin: 0 -20px;

  @include mqMaxWidth($screenS) {
    margin: 0;
    padding-top: 10px;
  }
}

.buttonsWrp {
  padding: 20px 20px 0;
}

.submitButton,
.cancelButton {
  float: left;
}

.tip {
  background-color: $backgroundHighlightColor;
  padding: 15px;
  margin: 20px;

  @include mqMaxWidth($screenS) {
    margin: 10px;
  }
}

.agree {
  padding: 0 20px;

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}
