@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.truncationModal {
  :global(.modalBody) {
    @include mqMaxWidth($screenS) {
      max-width: 100%;
    }
  }
}

.contentWrp {
  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.description {
  padding: 15px;
  background-color: $backgroundHighlightColor;
}

.areaTabsWrp {
  padding-top: 20px;
  margin-left: -20px;
  margin-right: -20px;

  @include mqMaxWidth($screenS) {
    padding-top: 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.radioGroupWrp {
  margin-top: 20px;
  margin-bottom: 0;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radio {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
