@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.layoutContentWrp {
  overflow: auto;
  position: relative;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  flex: auto;
  background: #f2f2f2;
}

.layoutContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;

  @include mqMaxWidth($screenS) {
    padding: 10px 0 0;
  }
}
