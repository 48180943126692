@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.iconTextButton {
  cursor: pointer;
  color: $actionColor;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin-right: 0 !important;
  padding: 0;

  &:hover {
    color: $actionColorHover;

    svg {
      fill: $actionColorHover;
    }
  }
}

.iconTextButton:disabled {
  margin-right: 0;
  padding: 0;

  svg,
  &:hover svg {
    fill: $secondaryColor;
  }
}

.iconTextWrapper {
  display: flex;
  align-items: center;
}

.iconText {
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;

  svg {
    fill: $actionColor;
  }
}

.iconLabel {
  margin-bottom: 6px;
}

.isFetchingIconTextButton {
  display: none !important;
}
