@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.notification {
  background-color: #fef7db;
  padding: 20px;

  @include mqMaxWidth($screenS) {
    padding: 10px;
  }
}
