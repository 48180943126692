@import "@/Framework/GlobalStyles/variables.scss";
@import "@/dataroom/ui/components/Dataroom/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.navigationItem {
  display: block;
  padding: 3px 21px;
  white-space: nowrap;
  transition: $backgroundTransition;

  &:hover,
  &.navigationActiveItem {
    background: #ebebeb;

    .navigationItemIcon {
      fill: $actionColorHover;
    }
  }

  @include mqMaxWidth($screenS) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.navigationItemIcon {
  position: relative;
  top: 2px;
  margin-right: 10px;
  fill: $actionColor;
}

.navigationItemText {
  color: $titleColor;
}
