$manageColor: #9984b3;
$viewAndDownloadColor: #4ab675;
$viewOnlyColor: #fdca2c;
$noAccessColor: #cc4d4d;

.managePermission {
  svg {
    fill: $manageColor;
  }
}

.viewAndDownloadPermission {
  svg {
    fill: $viewAndDownloadColor;
  }
}

.viewOnlyPermission {
  svg {
    fill: $viewOnlyColor;
  }
}

.noAccessPermission {
  svg {
    fill: $noAccessColor;
  }
}

.checkboxWrp {
  input:checked {
    + .checkboxControl:before {
      border-width: 0;
    }

    + .checkboxControl:after {
      display: none;
    }

    + .managePermission {
      &.checkboxControl:before {
        background-color: $manageColor;
      }
    }

    + .viewAndDownloadPermission {
      &.checkboxControl:before {
        background-color: $viewAndDownloadColor;
      }
    }

    + .viewOnlyPermission {
      &.checkboxControl:before {
        background-color: $viewOnlyColor;
      }
    }

    + .noAccessPermission {
      &.checkboxControl:before {
        background-color: $noAccessColor;
      }
    }
  }
}
