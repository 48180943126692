@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$footerBottomBackgroundColor: #1c1c1c;

.tenant-finsight-topBackgroundColor {
  background-color: $firmColor !important;
}
.tenant-finsight-bottomBackgroundColor {
  background-color: $footerBottomBackgroundColor !important;
}

.tenant-evercall-topBackgroundColor,
.tenant-dealvdr-topBackgroundColor,
.tenant-17g5-topBackgroundColor,
.tenant-investorset-topBackgroundColor,
.tenant-dealroadshow-topBackgroundColor {
  background-color: #fff !important;
}

.tenant-evercall-bottomBackgroundColor,
.tenant-dealvdr-bottomBackgroundColor,
.tenant-17g5-bottomBackgroundColor,
.tenant-investorset-bottomBackgroundColor,
.tenant-dealroadshow-bottomBackgroundColor {
  background-color: #4c3269 !important;
}

.tenant-fallback-topBackgroundColor,
.tenant-fallback-bottomBackgroundColor {
  background-color: $defaultBackgroundColor;
}
