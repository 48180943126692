@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.description {
  font-size: 11px;
  color: $secondaryColor;
  margin: 18px;
  padding: 0;
}

.containerClassName {
  min-height: 45px;
}

.dataTable {
  display: flex;
  width: 100%;
  flex-direction: column;

  thead,
  tbody,
  tr {
    display: flex;
  }

  thead tr {
    position: relative;
    width: 100%;
    background-color: $secondary5Color;
    display: flex;
    justify-content: flex-end;

    th {
      background-color: $secondary5Color;
      border-bottom-width: 0;
      padding: 9px;
    }

    td {
      padding: 12px 9px;
    }
  }

  tbody {
    overflow-y: auto;
    flex-direction: column;
    max-height: 200px;

    tr {
      td {
        padding: 6px 9px;
      }
    }
  }

  tr {
    min-height: 34px;
  }

  tbody td,
  thead th {
    display: flex;
    flex-shrink: 0;
    min-height: 34px;

    &:first-child {
      flex-shrink: 1;
      flex-grow: 1;
    }

    @include mqMaxWidth($screenS) {
      max-width: 45px;

      &:first-child {
        max-width: none;
      }

      &:last-child:not(:first-child) {
        margin-right: 10px;
      }
    }
  }

  &.permissionsEmptyMessage {
    tbody {
      max-height: 100%;
    }

    tbody tr td:first-child {
      width: 100%;
      padding: 0;
      justify-content: center;
    }
  }
}

.permissionGroupNameColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyMessage {
  padding: 40px 0;
}

table.isEmpty {
  tbody {
    max-height: none;
  }

  td {
    justify-content: center;
  }
}

.refreshBtn {
  display: block;
  margin: 20px auto 0;
  font-weight: bold;

  i {
    top: 0;
  }
}

.tooltipContainer {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
}

.tooltipWrapper {
  max-width: calc(100% - 20px);
}

.nameColumnWrp {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 100px;
  white-space: nowrap;
}

.title {
  font-size: 14px;
  line-height: 18px;
  color: $titleColor;
  text-transform: capitalize;
}

.warningIconContainer {
  margin-left: 5px;
  transform: translateY(-2px);
}

.warningIcon {
  width: 12px;
  fill: $cancelColor;
}
