/* VideoJS - latin */
@font-face {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    local('VideoJS'),
    url('/_next/static/frontend/static/fonts/videojs/VideoJS.woff2') format('woff2'),
    url('/_next/static/frontend/static/fonts/videojs/VideoJS.woff') format('woff');
}
