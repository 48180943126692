@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: $defaultBackgroundColor;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;

  .messageWrapper {
    display: block;
    text-align: center;
    margin-top: 40px;
  }

  .messageHeader {
    margin: 0;
  }

  .message {
    margin-top: 20px;
    font-size: 14px;
  }

  .reloadWrapper {
    display: inline-block;
    margin-top: 40px;
  }

  .reloadLink {
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    color: $actionColor;

    &:hover {
      color: $actionColorHover;
      .reloadIcon {
        fill: $actionColorHover;
      }
    }
  }

  .reloadIcon {
    top: 1px;
    margin-right: 9px;
    cursor: pointer;
    height: 12px;
    width: 12px;
    fill: $actionColor;
  }
}
