@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.emailAlertsWrp {
  color: $actionColor;
  cursor: pointer;
  display: table;
  table-layout: fixed;
  width: 100%;
  min-width: 170px;
}

.emailAlertsTooltipContainer {
  vertical-align: top;
  max-width: 100%;
}

.emailAlertsContainer {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.rowWrp:not(:last-child):after {
  content: ';';
}
