@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.contentWrp {
  position: relative;
  padding-top: 10px;

  @include mqMaxWidth($screenS) {
    padding: 10px 10px 0;
  }
}

.inlineFieldWrp {
  display: flex;
}

.formField {
  flex-grow: 1;

  @include mqMaxWidth($screenS) {
    width: 100%;
    margin-top: 10px;
  }
}

.formLabel {
  color: $titleColor;
}
