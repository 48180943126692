@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$lineColor: #dadada;
$hoverBackground: #ebebeb;
$nodeHeight: 26px;
$nodeTextColor: #2d2d2d;

.isNotLast:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  margin-left: -44px;
  background: $lineColor;
}

.isNotRoot:after {
  content: '';
  position: absolute;
  margin-top: -33px;
  margin-left: -44px;
  width: 17px;
  height: 20px;
  border-left: 1px solid $lineColor;
  border-bottom: 1px solid $lineColor;
}

.nodeItem {
  white-space: nowrap;
  padding-left: 45px;
}

.nodeItemTitle {
  height: $nodeHeight;
  color: $nodeTextColor;
  margin-left: -5px;
  padding-left: 5px;
  line-height: $nodeHeight;
  padding-right: 20px;
}

.nodeFolderIcon {
  position: absolute;
  margin-left: -26px;
  margin-top: 5px;
  z-index: 1;
}

.nodeToggleIcon {
  position: absolute;
  z-index: 1;
  margin-left: -50px;
  margin-top: 6px;

  @include mqMaxWidth($screenS) {
    width: 15px;
    height: 15px;
  }
}

.nodeItem:hover,
.isSelected {
  .nodeItemTitle {
    background: $hoverBackground;
  }

  .nodeToggleIcon svg {
    fill: $actionColorHover;
  }
}

.hoverDisabled:hover {
  .nodeItemTitle {
    background: inherit;
  }

  .nodeFolderIcon svg {
    fill: #fdca2c;
  }
}

.nodeItemDropHover {
  .nodeItemTitle {
    background: $backgroundHighlightColor;
  }
}

.isRoot {
  .nodeItemTitle {
    margin-left: -45px;
    padding-left: 45px;
  }
}

.isRoot > .contextMenuTriggerWrp > .nodeItem {
  padding-left: 21px;

  .nodeFolderIcon {
    margin-left: -26px;
  }

  .nodeItemTitle {
    left: 16px;
  }
}

.folderLink {
  user-select: none;
  -webkit-user-drag: none;
}
