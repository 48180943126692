@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/links.scss";

$animationSpeed: 200;
@value animationSpeed: #{$animationSpeed};

.ieWrapper {
  display: flex;
  flex-direction: column;
}

.container {
  font-size: 14px;
  line-height: 20px;
  color: $titleColor;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  transition: height $animationSpeed + ms, min-height $animationSpeed + ms, opacity $animationSpeed + ms;
  word-break: break-word;
}
.headerBanner {
  position: static;
}
.headerBannerFixed {
  position: fixed;
  width: 100%;
  top: 0;
}

.content {
  flex: 1;
  padding: 5px 10px;

  p {
    margin: 0;
  }
}

.closeIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-right: 10px;

  svg {
    width: 12px;
    height: 12px;
    fill: $actionColor;

    &:hover {
      fill: $actionColorHover;
    }
  }
}

.closedBanner {
  min-height: 0;
  height: 0;
  opacity: 0.3;
}

