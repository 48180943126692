@import "@/Framework/GlobalStyles/variables.scss";
@import "@/dataroom/ui/components/Dataroom/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.quickFiltersWrp {
  padding: 0;
  white-space: nowrap;
}

.quickFiltersHeader {
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 3px 20px;

  &:hover {
    background: #ebebeb;

    .toggleIcon {
      fill: $actionColorHover;
    }
  }

  @include mqMaxWidth($screenS) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.toggleIcon {
  position: relative;
  top: 2px;
  width: 13px;
  height: 13px;
  fill: $actionColor;
  margin-right: 12px;
  margin-left: 1px;

  @include mqMaxWidth($screenS) {
    width: 15px;
    height: 15px;
  }
}

.titleItem {
  color: $titleColor;
}

.quickFiltersItem {
  position: relative;
  margin: 0;
  padding: 3px 0 0 21px;

  @include mqMaxWidth($screenS) {
    padding-top: 10px;
  }

  &:hover,
  &.quickFiltersActiveItem {
    background: none;

    .quickFiltersItemText {
      background: #ebebeb;
    }
  }

  &:before {
    content: '';
    width: 17px;
    height: 1px;
    position: absolute;
    margin-left: -39px;
    margin-top: 11px;
    background: #ccc;

    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}

.quickFiltersItemIcon {
  position: absolute;
  top: 7px;
  left: 0;

  @include mqMaxWidth($screenS) {
    top: 14px;
  }
}

.quickFiltersItemText {
  display: block;
  padding: 3px 5px;
}

.quickFiltersChildren {
  position: relative;
  padding-left: 45px;

  @include mqMaxWidth($screenS) {
    padding-left: 35px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    background: #dadada;
    margin-left: -18px;
    top: -6px;
    bottom: 12px;

    @include mqMaxWidth($screenS) {
      display: none;
    }
  }
}
