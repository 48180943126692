@import "@/dataroom/ui/components/Dataroom/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.questionsItem {
  padding: 10px 20px;
  display: block;
  white-space: nowrap;
  transition: $backgroundTransition;

  @include mqMinWidth($screenS + 1) {
    &:hover,
    &.questionsItemActive {
      background: #ebebeb;

      .questionsItemIcon {
        path {
          fill: $actionColorHover;
        }
      }
    }
  }

  @include mqMaxWidth($screenS) {
    padding-left: 10px;
    padding-right: 10px;

    &:first-child {
      padding-top: 5px;
    }
  }
}

.questionsItemIcon {
  position: relative;
  top: 2px;
  path {
    fill: $actionColor;
  }
}

.questionsItemText {
  color: $titleColor;
  padding-left: 10px;
  position: relative;
  top: -1px;
}

.countLabel {
  float: right;
  margin-top: 5px;
}

.countLabelSpinner {
  float: right;
  margin-top: 4px;
  width: 13px;
  height: 13px;
}
