@import "@dealroadshow/uikit/core/styles/normalize.scss";
@import "fonts.scss";
@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";

// Animation
@import "@dealroadshow/uikit/core/styles/animation/Gradient.scss";
@import "@dealroadshow/uikit/core/styles/animation/rotateClockwise.scss";
// ContactSupport
@import "@/Framework/UI/Molecules/ContactSupport/contactSupport.scss";

html,
body {
  text-rendering: optimizeLegibility;
  font-smooth: always;
  font-smoothing: antialiased;
  font-size: $baseFontSize;
  color: $baseTextColor;
  line-height: $baseLineHeight;
  min-width: $screenXXs;
}

*,
*:after,
*:before {
  font-family: $fontFamily;
  box-sizing: border-box;
}

a,
.link {
  cursor: pointer;
  color: $actionColor;
  text-decoration: none;

  &:hover {
    color: $actionColorHover;
  }

  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  @extend .isH1;
}

h2 {
  @extend .isH2;
}

h3 {
  @extend .isH3;
}

#portal {
  position: absolute;
  min-width: $screenXXs;
}

/**
  * uikit
 */
:global {
  @import "@dealroadshow/uikit/core/main";
}
