@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/common/Questions/QuestionPriority/questionPriority.scss";

.inputsWrp {
  margin-top: 20px;
}

.deleteButton {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  top: -10px;
  right: -6px;

  height: 12px;
  width: 12px;
  padding: 0;
  margin: 0;

  border-radius: 50%;
  background: $cancelColor;

  &:hover {
    background: $cancelColor;
  }
}

.deleteButtonIcon {
  width: 6px;
  height: 6px;

  position: absolute;
  top: 1px;
  right: 2px;
  svg {
    width: 6px;
    height: 6px;

    fill: $btnDefaultColor;
    margin-top: 1px;
  }
}

.addMoreButton {
  padding: 0;
  height: 26px;

  svg {
    fill: $actionColor;
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }

  &:disabled {
    padding: 0;
    height: 26px;
    pointer-events: none;
  }

  @include mqMaxWidth($screenS) {
    padding-left: 0;
  }
}

.addMoreIcon {
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;

  &.disabled {
    svg {
      cursor: default;
      fill: $secondaryColor;

      &:hover {
        fill: $secondaryColor;
      }
    }
  }
}

.textarea {
  textarea {
    font-size: 14px;
    line-height: 16px;
    color: $titleColor;
  }
}

.fieldset {
  position: relative;
  border: 1px dashed #c0c0c0;

  padding: 20px;
  margin-bottom: 20px;

  &:hover {
    background: #f9f9f9;

    .deleteButton {
      opacity: 1;
      visibility: visible;
    }
  }

  @include mqMaxWidth($screenS) {
    padding: 20px 10px 10px;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  legend {
    font-size: 10px;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $secondary3Color;
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;

  @include mqMaxWidth($screenS) {
    flex-direction: column;
  }
}

.categoryFormField {
  margin-bottom: 20px;

  width: 100%;
  max-width: 375px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
    order: 2;
  }
}

.priorityFormField {
  margin-bottom: 20px;
}

.categoryWrapper {
  flex-grow: 1;
  margin-bottom: 0;
}

.priorityWrapper {
  @include mqMaxWidth($screenS) {
    order: 1;
  }
}

.questionDescriptionWrapper > div {
  margin-bottom: 0 !important;
}
