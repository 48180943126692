.radioGroupContainer {
  display: flex;
  flex-direction: column;
}

.radioItemWrp {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 10px;
  }
}
