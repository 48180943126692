@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.tabsWrp {
  padding-top: 10px;
  padding-left: 20px;
  border-bottom: 1px solid $secondary2Color;

  @include mqMaxWidth($screenS) {
    padding-left: 10px;
  }
}
