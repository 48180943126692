@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/helpers/wordWrap.scss";

.folderIcon {
  width: 24px;
  height: 21px;

  i {
    width: inherit;
    height: inherit;
  }
}

.titleWrp {
  color: $titleColor;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  @include break-word;

  @include mqMaxWidth($screenS) {
    padding: 20px 10px;
  }
}

.inputWrp {
  max-width: 357px;
  min-height: 96px;
  width: 100%;

  @include mqMaxWidth($screenS) {
    min-height: 76px;
  }
}
