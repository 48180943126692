@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.sectionWrp {
  padding: 20px 20px 0;
  text-align: center;

  @include mqMaxWidth($screenS) {
    padding: 10px 0 0;
  }
}

.folderIcon {
  width: 36px;
  height: 32px;

  i {
    width: inherit;
    height: inherit;
  }
}

.titleWrp {
  color: $titleColor;
  padding: 20px 0 0;
}

.buttonsWrp {
  padding-top: 20px;

  &.isPermissionsSectionVisible {
    @include mqMaxWidth($screenS) {
      padding: 20px 10px 0;
    }
  }
}

.submitButton,
.cancelButton {
  float: left;
}

.changePermissionsIcon {
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;

  svg {
    fill: $actionColor;
  }

  @include mqMaxWidth($screenS - 1) {
    margin-right: 0;
  }
}

.changePermissionsButton {
  cursor: pointer;
  color: $actionColor;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin-right: 0;
  padding: 0;

  &:hover {
    color: $actionColorHover;

    svg {
      fill: $actionColorHover;
    }
  }
}

.changePermissionsButton:disabled {
  margin-right: 0;
  padding: 0;

  svg,
  &:hover svg {
    fill: $secondaryColor;
  }
}

.changePermissionsButtonText {
  @include mqMaxWidth($screenS - 1) {
    display: none;
  }
}

.tooltipContainer {
  float: right;
}
