@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contentWrp {
  position: relative;
}

.disclaimerWrp {
  padding: 20px;
  background: $disclaimerColor;

  @include mqMaxWidth($screenS) {
    margin: 0 10px;
  }
}

.disclaimerBold {
  color: $cancelColor;
  font-weight: bold;
}

.deleteListWrp {
  overflow: auto;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include mqMaxWidth($screenS) {
    margin: 10px 10px 0;
  }
}

.deleteItem {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  padding: 7px 10px;
  color: $titleColor;
  background: $secondary4Color;
  border-bottom: 1px solid #e4e4e4;
}

.fileSystemIcon {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.sizeColumn {
  padding-left: 30px;
  color: $secondaryColor;
}

.submitButton {
  background-color: $cancelColor;

  &:hover {
    background-color: #a74040;
  }
}
