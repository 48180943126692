@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.inputsWrp {
  list-style-type: none;
  padding: 0;
  max-width: 285px;
  margin: 0 auto 30px;

  @include mqMaxWidth($screenXs) {
    max-width: 256px;
  }
}

.inputWrp {
  position: relative;

  &:first-child {
    margin-top: 20px;
  }

  > div {
    min-height: 56px;
    margin-bottom: 10px;
  }
}

.removeButton {
  padding: 0 12px;
  position: absolute;
  right: -46px;
  top: 0;
  margin-right: 0;

  @include mqMaxWidth($screenXs) {
    padding: 0;
    right: -23px;
  }
}

.removeButtonIcon {
  width: 12px;
  height: 12px;

  svg {
    fill: $cancelColor;
  }
}

.addMoreIcon {
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}

.addMoreButton {
  margin-right: 0;
  margin-top: -20px;

  svg {
    fill: $actionColor;
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }
}
