@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contextMenu {
  min-width: 200px;
}

.hiddenContextMenu {
  display: none;
}

.actionButtonMenuItem {
  padding: 0 20px;

  &:hover button {
    background-color: transparent;
    color: $actionColorHover;

    i > svg {
      fill: $actionColorHover;
    }
  }

  &:hover {
    background-color: $secondary5Color;
  }

  @include mqMaxWidth($screenS) {
    > div > span {
      padding-left: 0;

      i {
        top: 4px;
        left: 0;
      }
    }
  }

  button {
    float: none;
    font-weight: normal;

    &:hover {
      color: $actionColorHover;

      svg {
        fill: $actionColorHover;
      }
    }
  }

  .contextMenuItem {
    padding: 0;
    height: 36px;

    i {
      height: 16px;
      width: 16px;
    }

    &.isWarning {
      color: $cancelColor;

      svg {
        fill: $cancelColor;
      }

      &:hover {
        color: $cancelColorHover;

        svg {
          fill: $cancelColorHover;
        }
      }
    }

    &.isDisabled {
      color: $secondary2Color;
      background: transparent;
      font-weight: 400;
      cursor: unset;

      svg {
        fill: $secondary2Color;
      }

      &:hover {
        color: $secondary2Color;
        background: transparent;

        svg {
          fill: $secondary2Color;
        }
      }
    }
  }

  .infoButton {
    padding: 0;

    button {
      width: 100%;

      svg {
        fill: $actionColor;
      }

      &:hover {
        color: $actionColorHover;

        svg {
          fill: $actionColorHover;
        }
      }
    }
  }
}

.redactButton button {
  width: 100%;
}

.copyLinkButton {
  button {
    margin-right: 0;
  }

  @include mqMaxWidth($screenS) {
    > button {
      i {
        top: 0;
        left: -1px;
      }

      > div > div {
        margin-top: -1px;
      }
    }
  }
}

.separator {
  height: 1px;
  background-color: $defaultTableBorderColor;
  margin: 5px 0;

  &:last-child {
    display: none;
  }
}
