@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.fileName {
  display: flex;
  white-space: nowrap;

  > span {
    display: inline-block;
    vertical-align: bottom;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -4px;
    color: $titleColor;
  }

  .extension {
    transform: translateX(4px);
  }

  .nameCellWidth {
    max-width: 217px;
  }
}
