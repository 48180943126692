@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.toolbarIconButtonWrp {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.tooltipWrp,
.tooltipContainer {
  width: 100%;
  height: 100%;
}

.toolbarIconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  .toolbarIcon {
    margin-right: 0;
    top: 0;

    > svg {
      fill: $actionColor;
    }
  }

  &:disabled {
    background: transparent;
    padding: 0;
    margin-right: 0;
    pointer-events: none;

    .toolbarIcon {
      position: relative;
      top: 0;

      > svg {
        fill: $secondary2Color;
      }
    }
  }
}
