@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.tableContainer {
  max-height: 304px;
  overflow: auto;
}

.table {
  tbody {
    tr {
      background-color: $secondary5Color;
      border-bottom-color: #f0f0f0;
      position: relative;

      &:global(.is-selected) {
        background-color: $disclaimerColor;

        td:last-child {
          position: absolute;
          right: 0;
        }

        .nameCell {
          padding-right: 250px;

          @include mqMaxWidth($screenS) {
            padding-right: 0;
          }
        }
      }

      &:first-child {
        border-top: 1px solid #f0f0f0;
      }

      td {
        padding-top: 6px;
        padding-bottom: 6px;

        &:empty {
          display: none;
        }
      }
    }
  }
}

.filesystemIcon {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.fileIndexCell {
  color: $secondaryColor;
  white-space: nowrap;
}

.nameCell {
  display: flex;
  color: $titleColor;
  word-break: break-all;
}

.canMoveCell {
  color: $cancelColor;
  font-size: 11px;
  line-height: 12px;
  white-space: nowrap;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.destinationTable {
  margin-bottom: 10px;

  .canMoveCell {
    display: none;
  }
}
