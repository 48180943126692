@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.itemAlerts {
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    @include mqMaxWidth($screenS) {
      width: 100%;
      padding: 5px 0;
    }
  }
}

.timeSelect {
  width: 76px;
}

.selectDaysOfWeek {
  width: 120px;
}

.wrpSelect {
  margin-left: 20px;

  @include mqMaxWidth($screenS) {
    margin-left: 0;
  }
}

.dayWeek {
  margin-left: 5px;
}

.inlineBlock {
  display: inline-block;
  vertical-align: middle;
}

.footer {
  color: $titleColor;
  margin-top: 20px;

  @include mqMaxWidth($screenS) {
    margin-top: 10px;
  }
}

.strong {
  @include mqMaxWidth($screenS) {
    font-weight: 500;
  }
}
