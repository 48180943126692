@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.dialInsSection {
  position: relative;
}

.dialInsSectionVisible {
  font-size: 12px;
  line-height: 17px;
  min-height: 18px;
  color: $secondary3Color;
  margin: 10px 0 -10px;

  @include mqMaxWidth($screenS) {
    padding: 10px;
    margin-bottom: 0;
  }
}

.dialInsWrapper {
  display: inline;
  cursor: pointer;
}

.dialInsText {
  color: $actionColor;
}

.overlay {
  background-color: rgba(#f2f2f2, 0.8);
  &:last-child {
    display: none;
  }

  .spinner {
    margin-top: -8px;
    margin-left: -8px;
  }
}
