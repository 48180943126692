@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.emailModal {
  :global(.modalDialog) {
    max-width: 600px;

    @include mqMaxWidth($screenS) {
      max-width: 100%;
    }
  }
}

.contentWrp {
  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.description {
  padding-bottom: 20px;
}

.archiveSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px 20px;
  margin-bottom: 20px;
  background-color: $backgroundHighlightColor;
}

.archiveWrapper {
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.archiveIcon {
  fill: #b4680f;
  width: 21px;
  height: 24px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 20px;
  text-align: center;
  color: $titleColor;
}

.support {
  font-size: 12px;
  line-height: 17px;
  color: $secondary3Color;
}

.input {
  width: 100%;
  margin-bottom: 18px;
}

.error {
  font-size: 11px;
  line-height: 15px;
  color: $cancelColor;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
