@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.labelFixedWidth {
  width: 140px;
  padding: 10px 0;

  @include mqMaxWidth($screenXs) {
    width: 79px;
  }

  input:not(:checked) ~ .label {
    color: $secondary3Color !important;
  }

  input:checked ~ .label {
    color: $titleColor !important;
  }
}

.inlineBlock {
  display: inline-block;
  vertical-align: middle;
}
