@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.cancelChangesModal {
  .modalBody {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
  }

  :global(.modalDialog) {
    max-width: 450px;
  }
}

.discardButton {
  background: $cancelColor;

  &:hover {
    background-color: #a83737;
  }
}
