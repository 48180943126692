@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.textButtonWrp {
  button {
    font-weight: normal;
    width: 100%;
    text-align: left;
  }
  button:disabled {
    font-weight: normal !important;
    text-align: left;
  }
}

.actionButtonWrp,
.countableButtonWrp {
  display: inline-block;
}

.iconButtonWrp {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  width: auto;
  height: auto;
}

.iconButtonContainer {
  width: 100%;
  height: 100%;
}

.iconButton {
  &:hover {
    background-color: transparent;
  }
}

.actionButtonTitle {
  display: flex;
  align-items: center;
}

.isDisabled {
  color: $secondaryColor !important;
}

.spinner {
  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.inlineSpinner {
  border-color: rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  margin-left: 10px;
}

.isFetchingIconButtonContainer {
  display: none;

  @include mqMaxWidth($screenS) {
    display: inline-flex;
  }
}
