@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.inlineFieldWrp {
  display: flex;
  width: 100%;
}

.formField {
  flex-grow: 1;

  @include mqMaxWidth($screenS) {
    width: 100%;
    margin-top: 10px;
  }
}

.formLabel {
  color: $titleColor;
}

.formSection {
  padding-top: 50px;

  @include mqMaxWidth($screenS) {
    padding-top: 30px;
  }
}
