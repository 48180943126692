@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/helpers/wordWrap.scss";

.conflictModal {

  :global(.modalDialog) {
    max-width: 600px;

    @include mqMaxWidth($screenS) {
      max-width: 100%;
    }
  }

  h4 {
    margin: 3px 0;
    font-weight: 500;
  }
}

.contentWrp {
  position: relative;

  strong {
    @include break-word;
  }

  @include mqMaxWidth($screenS) {
    padding: 0 10px 10px;
  }
}

.resolvingMethod {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.alert {
  margin-top: 15px;
}

.createCopyButton {
  i {
    vertical-align: middle;
    margin-right: 10px;
  }

  svg {
    fill: $actionColor;
  }

  &:hover svg {
    fill: $actionColorHover;
  }

  &:disabled svg {
    fill: $secondaryColor;
  }
}

.buttonsWrp {
  button:last-child {
    margin-right: 0;
  }

  @include mqMaxWidth(348px) {
    .actionButtons {
      margin-bottom: 10px;
    }
  }
}

.actionButtons {
  display: inline-block;
}
