@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.fallbackContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: $baseTextColor;
  font-size: 14px;
  text-align: center;
  min-height: 100px;
  height: 100%;
  width: 100%;

  .messageWrapper {
    text-align: center;
    width: 65%;
  }

  .reloadLink,
  .reloadIcon {
    color: $actionColor;
    text-decoration: none;

    &:hover {
      color: $actionColorHover;
      fill: $actionColorHover;
    }
  }

  .reloadIcon {
    position: relative;
    top: 1px;
    margin-left: 7px;
    cursor: pointer;
    fill: $actionColor;
    height: 12px;
    width: 12px;
  }
}
