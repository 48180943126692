@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";

.bulkPinningModal {
  .description {
    background-color: $backgroundHighlightColor;
    padding: 20px;
  }

  :global {
    .modalDialog {
      max-width: 430px;
    }

    .modalBody {
      padding: 0 20px 20px;

      @include mqMaxWidth($screenS) {
        padding: 15px;
      }
    }

    .modalHeader {
      @include mqMaxWidth($screenS) {
        padding-bottom: 5px;
      }
    }

    .modalContent {
      @include mqMaxWidth($screenS) {
        margin-bottom: 15px;
      }
    }

    .modalActions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .switcherWrp {
    margin-bottom: 10px;
    .switcherRadio {
      padding-bottom: 10px;
    }
  }
}
