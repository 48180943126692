@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.modal {
  :global(.modalBody) {
    padding-bottom: 16px;
  }

  :global(.modalDialog) {
    max-width: 800px;
  }
}

.container {
  background-color: $backgroundHighlightColor;
  padding: 20px;
  position: relative;

  &.bottomSection {
    margin-top: 10px;

    .alertsTitle {
      margin-bottom: 30px;
    }
  }

  @include mqMaxWidth($screenXs) {
    padding: 10px;
  }
}

.alertsTitle {
  margin-bottom: 20px;
}
