@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.buttonWrapper {
  > span {
    @include mqMaxWidth($screenS) {
      vertical-align: baseline;
      width: 100%;
      padding: 0 20px;
    }
  }

  @include mqMaxWidth($screenS) {
    .copyButton {
      text-align: left;

      > span {
        vertical-align: 0;
      }
    }

    .button,
    .button:disabled {
      line-height: 36px;
      height: 36px;
      float: none;
      font-weight: 400;
      width: 100%;
    }
  }
}

.copyButton {
  width: 100%;
  text-align: start;

  i {
    margin-right: 11px;
    top: 4px;
    left: 1px;

    @include mqMaxWidth($screenS) {
      top: -1px;
      left: 0;
    }
  }
}

.iconCheck {
  margin-right: 10px;
  width: 12px;
  height: 9px;

  svg {
    fill: #fff;
  }
}

.buttonInner {
  display: flex;
  align-items: center;
}

.buttonTitle {
  margin-right: 5px;
  font-weight: 400;
}
