@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$selectHoverColor: #8c8c8c;
$selectArrowColor: #c4c4c4;
$selectHoverColor: $secondary3Color;
$inputDisabledBgColor: #f3f3f3;
$dropdownBorderTopColor: #f3f3f3;

// Forced to disable these rules as I need to overwrite the 'react-select' library styles.
/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
.selectEncapsulation,
.selectPortalEncapsulation {
  :global {

    .Select {
      > span {
        z-index: 1 !important;
      }

      [class$="-indicatorContainer"] {
        padding: 0;

        svg {
          display: none;
        }
      }

      &.drop-up {
        [class$="-indicatorContainer"] {
          color: hsl(0, 0%, 80%);
        }

        > [class$="-menu"] {

          .is-selected {
            > [class$="-option"] {
              background-color: transparent !important;
              color: $selectHoverColor !important;
              position: relative;

              &:after {
                content: "";
                position: absolute;
                right: 10px;
                border: solid $selectArrowColor;
                border-width: 0 2px 2px 0;
                width: 6px;
                height: 8px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                margin-top: -2px;
              }
            }
          }

          div {
            text-align: left;
          }
        }
      }

      .Select-menu-wrapper {
        [class$="-menu"] {

          > [class$="-MenuList"] {

            [class$="-NoOptionsMessage"] {
              text-align: left;
              min-height: 30px;
            }
          }

          div {
            text-align: left;
          }
        }

        .Select-menu-list-option {
          > [class$="-option"] {
            color: $titleColor !important;
          }

          &.is-selected {
            > [class$="-option"] {
              background-color: transparent !important;
              color: $selectHoverColor !important;
              position: relative;

              &:after {
                content: "";
                position: absolute;
                right: 10px;
                border: solid $selectArrowColor;
                border-width: 0 2px 2px 0;
                width: 6px;
                height: 8px;
                top: 50%;
                transform: rotate(45deg) translateY(-50%);
                margin-top: -2px;
              }
            }
          }
        }
      }

      &.is-open > .Select-control span.Select-arrow {
        border: solid $selectArrowColor;
        border-width: 0 2px 2px 0;
        transform: rotate(-135deg);
        top: 0;
      }

      .Select-control {
        border-radius: 3px;
        font-size: $baseFontSize;
        height: 35px;
        border-color: $secondary2Color;

        .Select-value > .Select-value-label {
          color: $titleColor;
        }

        &:hover {
          box-shadow: none;
          border-color: $selectHoverColor;

          span.Select-arrow {
            border: solid $selectHoverColor;
            border-width: 0 2px 2px 0;
          }
        }
      }

      &.is-focused {
        .Select-control,
        .Select-menu-outer,
        &:not(.is-open) > div.Select-control {
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
          border-color: $selectHoverColor;
        }
      }

      &.drop-up .Select-menu-outer {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        box-shadow: none;
      }

      &.drop-up.is-open > .Select-control {
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &.drop-down .Select-menu-outer {
        top: 100%;
        bottom: auto;
        margin-top: -1px;
        margin-bottom: 0;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: none;
      }

      &.drop-down.is-open > .Select-control {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      &.is-open.is-focused {
        span.Select-arrow {
          border: solid $selectHoverColor;
          border-width: 0 2px 2px 0;
        }

        .Select-menu-outer {
          border-top-color: $selectHoverColor;
        }
      }

      &.isError {
        > div,
        .Select-control,
        &.is-focused > .Select-control {
          border-color: $cancelColor;
        }
      }

      &.is-open {
        .Select-menu-outer {
          border-top-color: $selectHoverColor;
        }

        &.Select--single {
          .Select-control {
            border-radius: 3px 3px 0 0;
          }
        }
      }

      &.is-disabled.has-value.Select--single > .Select-control {
        background-color: $inputDisabledBgColor;

        &:hover {
          border-color: $secondary2Color;

          span.Select-arrow {
            border: solid $selectArrowColor;
            border-width: 0 2px 2px 0;
          }
        }

        .Select-value .Select-value-label {
          color: $secondary3Color;
        }
      }

      &.isDisabled:disabled,
      &.isDisabled[disabled],
      .Select-menu-list-option.is-disabled [class$=-option] {
        background: $btnDefaultColor;
        color: $secondary3Color !important;
      }

      &--multi {
        .Select-multi-value-wrapper {
          width: 100%;
        }

        .Select-input {
          width: calc(100% - 10px);
        }

        .Select-value {
          position: relative;
          background: $secondary5Color;
          color: $baseTextColor;
          border: unset;
          border-radius: 3px;
        }

        .Select-value-icon {
          position: absolute;
          right: 7px;
          line-height: 25px;
          color: $actionColor;
          font-size: 19px;
          padding: 0;
          border: unset;

          &:hover {
            color: $actionColorHover;
            background: transparent;
          }
        }

        .Select-value-label {
          padding: 4px 24px 3px 9px;
        }
      }
    }

    .Select-option {
      color: $titleColor !important;
    }

    .Select-option.is-focused {
      background-color: $btnDefaultColor !important;
    }

    .Select-option.is-selected {
      position: relative;
      background-color: transparent !important;
      color: $secondary3Color !important;

      &:after {
        content: "";
        position: absolute;
        right: 10px;
        border: solid $selectArrowColor;
        border-width: 0 2px 2px 0;
        width: 6px;
        height: 8px;
        top: 50%;
        transform: rotate(45deg) translateY(-50%);
        margin-top: -2px;
      }
    }

    .Select-option.is-disabled {
      color: $secondary3Color !important;
      cursor: default;
    }

    .matchingText {
      color: $successColor;
      background: none;
    }

    .Select-input {
      height: 34px;
      width: 100%;
    }

    .Select-placeholder {
      color: $secondary3Color;

      .Select--single > .Select-control .Select-value {
        color: $placeholderColor;
        line-height: 34px;
      }
    }

    .Select-menu-outer {
      top: 98%;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      font-size: $baseFontSize;
    }

    span.Select-arrow {
      top: -3px;
      border: solid $selectArrowColor;
      border-width: 0 2px 2px 0;
      display: inline-block;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
    }
  }
}
.selectPortalEncapsulation {
  :global {
    .Select-menu-outer {
      [class$="-menu"] {

        div {
          text-align: left;
        }
      }

      > [class$="-MenuList"] {

        [class$="-NoOptionsMessage"] {
          text-align: left;
          min-height: 30px;
        }
      }
    }

    .Select-menu-list-option {
      > [class$="-option"] {
        color: $titleColor !important;
      }

      &.is-selected {
        > [class$="-option"] {
          background-color: transparent !important;
          color: $selectHoverColor !important;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            right: 10px;
            border: solid $selectArrowColor;
            border-width: 0 2px 2px 0;
            width: 6px;
            height: 8px;
            top: 50%;
            transform: rotate(45deg) translateY(-50%);
            margin-top: -2px;
          }
        }
      }
    }
  }
}
/* stylelint-enable max-nesting-depth, selector-max-compound-selectors */

.selectSpinner {
  margin-right: 5px !important;
  max-width: 16px;
  max-height: 16px;
}

.placeholder {
  color: $secondary3Color;
}

.multiValueRemoveIcon {
  width: 8px;
  height: 8px;
}

.countryFlagSingleValue {
  margin-right: 8px;
}

.countryFlagOption {
  margin-right: 10px;
}

.countryFlagMultiValue {
  margin-right: 7px;
}
