@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/dataroom/ui/components/Dataroom/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$sidebarMobileWidth: 300px;
$collapsedSidebarWidth: 56px;
$shadowMobileWidth: 16px;

.sidebarWrp {
  height: 100%;
  z-index: 4;

  .tabContentWrp {
    .logoWrp {
      display: none;
    }
  }

  @include mqMaxWidth($screenS) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);

    &.isMobileCollapsed {
      right: auto;

      .sidebar {
        left: - $sidebarMobileWidth;
      }
    }

    .toggleButtonWrp {
      position: static;
      width: 100%;
    }

    .logoWrp,
    .sliderButton,
    .sidebarFooter {
      display: none;
    }

    .tabsWrp {
      position: static;
      overflow: visible;
      border-top: 1px solid $defaultTableBorderColor;
      flex-grow: 1;

      .logoWrp {
        display: block;
      }
    }

    .tabsList {
      display: block;
    }

    .tabItemWrp {
      display: block;

      &:last-of-type > .tabItem {
        border-left: 0;
      }
    }

    .tabItem {
      height: $collapsedSidebarWidth;
      border: 0;
      background-color: $defaultBackgroundColor;
    }

    .isTabActive {
      background: #fff;
    }

    .tabContentWrp {
      display: none;
      left: $collapsedSidebarWidth;
      top: 0;
      bottom: 0;
      z-index: -1;
      min-width: $sidebarMobileWidth - $collapsedSidebarWidth;
      width: $sidebarMobileWidth - $collapsedSidebarWidth;
      overflow: auto;
      padding-top: 0;
      flex-direction: column;

      .tabContent {
        overflow: visible;
        position: static;
        flex-grow: 1;
        margin-top: 11px;
      }
    }
  }
}

.sidebar {
  width: 100%;
  height: 100%;
  box-shadow: $boxShadow;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;

  @include mqMaxWidth($screenS) {
    position: absolute;
    transition: left 300ms;
    left: 0;
    display: flex;
    flex-direction: column;
    width: $collapsedSidebarWidth;
    background-color: $defaultBackgroundColor;
    overflow: visible;

    &:after,
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
    }

    &:not(.noFrame):after {
      height: $shadowMobileWidth;
      left: $collapsedSidebarWidth;
      right: - ($sidebarMobileWidth - $collapsedSidebarWidth);
      background: linear-gradient(180deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
    }

    &:not(.noFrame):before {
      width: $shadowMobileWidth;
      top: 0;
      left: $sidebarMobileWidth - $shadowMobileWidth;
      background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 100%);
    }
  }
}

.isTransition {
  transition: width 0.5s ease-in-out;
}

.isCollapsed {
  width: $collapsedSidebarWidth !important;
  min-width: $collapsedSidebarWidth !important;

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .toggleButtonWrp {
    position: static;
    width: 100%;
  }

  .logoWrp,
  .sliderButton,
  .sidebarFooter {
    display: none;
  }

  .tabsWrp {
    flex-grow: 1;
    position: static;
    overflow: visible;
    border-top: 1px solid $defaultTableBorderColor;
  }

  .tabsList {
    display: block;
  }

  .tabItemWrp {
    display: block;

    > .tabItem.isTabActive {
      border: 0;
    }
  }

  .tabItem {
    height: $collapsedSidebarWidth;
    border-width: 0;

    &:hover {
      background: #f9f9f9;

      .tabItemIcon svg {
        fill: $actionColorHover;
      }
    }
  }

  .isTabActive {
    background: #f9f9f9;
  }

  .tabContentWrp {
    display: none;
    left: $collapsedSidebarWidth;
    top: -20px;
    bottom: 0;
    z-index: -1;
    box-shadow: $boxShadow;

    .logoWrp {
      display: block;
      width: 280px;
    }

    .tabContent {
      top: 85px;
    }
  }

  .tabItemWrp:hover .tabContentWrp {
    display: block;
  }

  .isLogoEmpty {
    .tabContentWrp {
      top: -65px;
    }
  }
}

.logoWrp {
  height: 65px;
  padding: 20px;
  width: $sidebarMobileWidth - $collapsedSidebarWidth;

  img {
    height: 100%;
    max-width: 100%;
  }

  .isTemplate {
    max-width: 100%;
    height: auto;
  }


  @include mqMaxWidth($screenS) {
    padding-bottom: 9px;
  }
}

.toggleButtonWrp {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 65px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleButton {
  display: inline-block;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 3px;

  i {
    width: 10px;
    height: 10px;
  }

  svg {
    stroke: $secondaryColor;
  }

  &:hover {
    background: #ebebeb;

    svg {
      stroke: #355788;
    }
  }
}

.tabsWrp {
  overflow: hidden;
  position: absolute;
  top: 65px;
  right: 0;
  bottom: 46px;
  left: 0;
}

.tabsList {
  display: flex;
}

.tabItemWrp {
  display: inline-block;
  vertical-align: top;
  flex-grow: 1;

  > .tabItem.isTabActive {
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;

    @include mqMaxWidth($screenS) {
      border-left: 0;
      border-right: 0;
    }
  }

  &:first-of-type > .tabItem {
    border-left: 0;
  }

  &:last-of-type > .tabItem {
    border-right: 0;
  }
}

.tabItem {
  width: 100%;
  height: 38px;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #dadada;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: $actionColor;
  }

  @include mqMinWidth($screenS + 1) {
    &:hover {
      background: #ebebeb;

      svg {
        fill: $actionColorHover;
      }
    }
  }
}

.isTabActive {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid transparent;

  svg {
    fill: $secondaryColor;
  }

  @include mqMinWidth($screenS + 1) {
    &:hover {
      background: transparent;

      svg {
        fill: $secondaryColor;
      }
    }
  }
}

.isTabActive + .tabContentWrp {
  @include mqMaxWidth($screenS) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    top: 0;
  }
}

.tabItemIcon {
  position: relative;
}

.tabContentWrp {
  padding: 20px 0 0;
  position: absolute;
  min-width: 280px;
  background: #fff;
  z-index: 1;
  top: 38px;
  right: 0;
  bottom: 0;
  left: 0;
}

.tabContent {
  position: absolute;
  overflow: auto;
  top: 20px;
  bottom: 0;
  left: 0;
  right: 0;

  &.dataroomTabContent {
    top: 10px;
  }
}

.sliderButtonWrp {
  position: absolute;
  right: 5px;
  top: 103px;
  bottom: 63px;
  width: 10px;
  z-index: 1;
}

.sliderButton {
  position: relative;
  top: 50%;
  margin-top: -8px;

  svg {
    width: auto;
    stroke: $secondaryColor;
  }
}

.closeIcon {
  svg {
    fill: $actionColor;
  }
}

.closeIconWrp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  min-height: 47px;
  width: $collapsedSidebarWidth;

  i {
    width: 12px;
    height: 12px;
  }
}

.dialInsSectionWrp {
  display: none;
  align-items: flex-end;
  overflow: auto;

  @include mqMaxWidth($screenS) {
    display: flex;
  }
}

.dialInsSection {
  text-align: center;
  padding: 20px 10px;
}

.tooltipContainer {
  display: flex;
  padding: 5px 0;
}

.contentWrp {
  @include mqMaxWidth($screenS) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.tabWrp {
  @include mqMaxWidth($screenS) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.mobileDataroomName {
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: $titleColor;

  border-bottom: 1px solid $defaultTableBorderColor;
  padding: 8px 10px 5px;
}
