@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/helpers/wordWrap.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

$folderIconColor: #fdca2c;

.uploaderModal {
  .modalBody {
    height: 550px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    &.disabledFolders {
      height: 600px;
    }
  }

  :global {
    .modalDialog {
      max-width: 785px;
    }

    @include mqMaxWidth($screenS) {
      .modalHeader {
        padding: 10px 10px 0;
      }

      .modalCloseBtn {
        top: 10px;
        right: 10px;
      }

      .modalFooter {
        padding: 0 10px 10px;
      }
    }
  }

  .infoMessage {
    @extend .flexCenter;

    padding: 10px;
    margin-top: 15px;
    color: $secondary3Color;
    background: $backgroundHighlightColor;

    .infoIcon {
      width: 14px;
      height: 14px;
      fill: $secondaryColor;
      margin-right: 10px;
    }
  }
}

.permissionsWrp {
  margin-bottom: 19px;

  tbody {
    max-height: 120px;

    @include mqMaxWidth($screenS) {
      max-height: none;
    }

    tbody td,
    thead th {
      @include mqMaxWidth($screenS) {
        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }

  .permissionsEmptyMessage {
    tbody {
      max-height: 100%;
    }
  }
}

.errorMessage {
  color: $cancelColor;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.emptyMessage {
  background: #fff;
  text-align: center;
}

.dataTableContainer {
  max-width: 100%;
  position: relative;

  table {
    border-collapse: separate;
  }

  table thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  table thead tr {
    border: 0;
  }

  table thead tr th {
    color: $secondaryColor;
    border-bottom: 1px solid $defaultBackgroundColor;
  }

  @include mqMaxWidth($screenS) {
    table thead tr th:first-child {
      padding-left: 10px;
    }

    table thead tr th:last-child {
      padding-right: 10px;
    }
  }

  table tbody tr td label {
    vertical-align: top;
  }

  table tbody tr {
    border: 0;

    td {
      padding: 7px 5px;
      border-bottom: 1px solid $defaultBackgroundColor;
    }

    > td:first-child {
      padding-left: 20px;

      @include mqMaxWidth($screenS) {
        padding-left: 10px;
      }
    }

    > td:last-child {
      padding-right: 20px;

      @include mqMaxWidth($screenS) {
        padding-right: 10px;
      }
    }
  }
}

.sectionWrp {
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  text-align: left;
  flex-grow: 1;
}

.folderIcon {
  width: 36px;
  height: 32px;

  svg {
    fill: $folderIconColor;
  }
}

.countLabel {
  width: auto;
  margin-left: 5px;
  padding: 0 5px;
  background-color: $actionColor !important;

  &.isActive {
    background-color: $baseTextColor !important;
  }
}

.titleWrp {
  color: $titleColor;
  padding: 20px 0 0;
}

.submitButton,
.cancelButton {
  float: left;
}

.changePermissionsIcon {
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;

  svg {
    fill: $actionColor;
  }

  @include mqMaxWidth($screenS - 1) {
    margin-right: 0;
  }
}

.changePermissionsButton {
  cursor: pointer;
  color: $actionColor;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin-right: 0 !important;
  padding: 0;

  &:hover {
    color: $actionColorHover;

    svg {
      fill: $actionColorHover;
    }
  }
}

.changePermissionsButton:disabled {
  margin-right: 0;
  padding: 0;

  svg,
  &:hover svg {
    fill: $secondaryColor;
  }
}

.changePermissionsButtonText {
  @include mqMaxWidth($screenS - 1) {
    display: none;
  }
}

.tabTitle {
  text-transform: capitalize;
}

.typesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;

  @include mqMaxWidth($screenS) {
    padding-left: 10px;
  }
}

.headerButtonsContainer {
  display: flex;
  align-items: center;

  padding-right: 20px;

  @include mqMaxWidth($screenS) {
    padding-right: 10px;
  }
}

.buttonsTypes {
  padding: 10px 19px;
  outline: none;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
}

.successWithErrorMessage {
  color: $baseTextColor;
  padding: 20px;

  @include mqMaxWidth($screenS) {
    flex-grow: 1;
    height: 100%;
  }
}

.text {
  color: $secondary3Color;
  font-size: 14px;
  margin-bottom: 3px;
}

.dropzoneWrp {
  height: 100%;

  @include mqMaxWidth($screenS) {
    padding: 10px;
  }
}

.dropzone {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 3px;
  border: 1px dashed $secondary2Color;
  outline: none;
  cursor: pointer;
  min-height: 76px;

  &:hover {
    border-color: $secondary3Color;

    .preview {
      border-color: $secondary3Color;
    }
  }
}

.fileNameCol,
.pathCol {
  flex-grow: 1;
}

.exportToExcelButton {
  margin-left: 14px;
  margin-right: -7px;

  &:hover {
    background-color: #eaeaea;
    border-radius: 2px;
    svg {
      fill: $actionColorHover;
    }
  }

  @include mqMaxWidth($screenS) {
    margin-left: 4px;
  }
}

.tooltipContainer {
  float: right;
}
