.documentPreviewer {
  z-index: 5;
}

.previewNotAvailableModal {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.securityMobileNotification {
  font-size: 12px;
  line-height: normal;
  min-width: 285px;
}
