@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrapper {
  margin-bottom: 20px;
  padding: 20px;
  background: $backgroundHighlightColor;

  &.editMode {
    padding: 12px 0 12px 12px;
  }

  @include mqMaxWidth($screenS) {
    padding: 10px;

    &.editMode {
      padding: 10px;
    }
  }
}
