@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.pageHeader {
  width: 100%;
  margin-bottom: 20px;
  @include clearfix;
}

.pageHeaderWithActions {
  @extend .pageHeader;

  position: relative;
  z-index: 1;

  @include mqMaxWidth(890px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.pageHeaderTitle {
  float: left;
  padding-top: 5px;
}

.pageHeaderActions {
  float: right;
}

.pageNavigation {
  position: relative;
  width: 100%;
  height: 36px;
  margin-bottom: 20px;
  @include clearfix;
}

.pageNavigationLinks {
  padding-top: 8px;
  overflow: auto;
  max-width: 100%;
  white-space: nowrap;
  flex: 1;

  a {
    margin-right: 35px;

    &.isActiveNavLink {
      color: #2d2d2d;
      font-weight: 500;
    }

    &.isDisabledNavLink {
      color: $secondaryColor;

      &:hover {
        cursor: default;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    .badge {
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      line-height: 12px;
      color: #fff;
      padding: 0 3px;
      height: 13px;
      border-radius: 2px;
      background-color: $secondaryColor;
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }

  .separator {
    width: 1px;
    height: 20px;
    background-color: $secondaryColor;
    display: inline-block;
    margin-right: 35px;
    vertical-align: middle;
  }
}

.pageNavigationActions {
  text-align: right;
  vertical-align: middle;
}

.actionIsDisabled {
  color: $secondaryColor;
  cursor: default;

  &:hover {
    color: $secondaryColor;
  }

  i {
    fill: $secondaryColor !important;
  }
}

.pageHeaderActionsWrapper {
  @extend .pageHeaderActions;

  min-height: 36px;
  position: absolute;
  bottom: -44px;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  @include mqMaxWidth(890px) {
    position: relative;
    bottom: auto;
    margin-left: auto;
    padding: 5px 0 0 20px;
    flex: 1 1 20%;
    justify-content: flex-end;
  }

  @include mqMaxWidth($screenXs) {
    padding-top: 0;
  }
}
