@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.questionWrp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.bottomActions {
  padding: 20px;

  @include mqMaxWidth($screenS) {
    padding: 10px;
  }
}
