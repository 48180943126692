@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/mixins/truncateTextLines.scss";

$searchSidebarColor: rgba(255, 255, 255, 0.4);

.header {
  padding: 0 5px;
  background-color: #3d905b;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  display: none;

  &.dealvdrHeader {
    background: linear-gradient(269.6deg, #3d906d 27.95%, #2b656d 98.54%);
  }

  @include mqMaxWidth($screenS) {
    display: flex;
  }

  .searchWrapper {
    .search {
      input {
        padding-left: 32px;
      }
    }
  }
}

.title {
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  max-height: 30px;
  color: #fff;
  padding: 0 20px;
  text-align: center;
  @include truncateTextLines(2);
}

.iconOuter {
  margin: 11px 0;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon {
  svg {
    fill: #fff;
  }
}

.visibleSearchWrp {
  .icon {
    height: 12px;
    width: 12px;
  }
}

.searchWrapper {
  flex-grow: 1;
  padding: 0 10px;
}

.search {
  input {
    background-color: rgba(0, 0, 0, 0.7);
    border: 0;
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;

    &:hover,
    &:active,
    &:focus {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &::placeholder {
      color: $searchSidebarColor;
    }
  }

  input + div {
    display: none;
  }

  svg {
    fill: $searchSidebarColor;
  }

  i {
    width: 12px;
    height: 12px;
    top: 8px;
  }
}

.searchEmpty {
  input {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
