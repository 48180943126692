@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.toggleIcon {
  width: 13px;
  height: 13px;
  z-index: 1;
  cursor: pointer;

  > svg {
    background: #fff;
    fill: $actionColor;
  }
}

tr:hover,
tr:global(.is-hovered) {
  .toggleIcon > svg {
    fill: $actionColorHover;
  }
}
