@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$warningMessageColor: #ffeded;

.cancelUploadModal {
  .modalBody {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 22px;
  }

  :global(.modalDialog) {
    max-width: 450px;
  }
}

.content {
  padding: 10px;
  background: $warningMessageColor;
}
