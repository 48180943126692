@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.headerIcon {
  width: 12px;
  height: 12px;
  margin-right: 7px;
  vertical-align: middle;
}

.headerWrp {
  @include mqMaxWidth($screenS) {
    margin: 0 auto;
  }
}

.headerLabelWrp {
  padding-left: 0 !important;

  @include mqMaxWidth($screenS) {
    padding-left: 5px !important;
  }
}

.headerLabel {
  color: $secondary3Color;
  vertical-align: middle;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.headerControl {
  display: none;
}
