@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.iconButtonWrp {
  margin-right: 13px;
}

.iconButtonWrp,
.iconButton {
  width: 30px;
  height: 30px;
  min-width: 30px;
}

.isFetching:not(.isDisabled).actionButton,
.isFetching:not(.isDisabled).actionButton:hover {
  background-color: $actionColor;

  > * {
    color: #fff;
    opacity: 0.5;
  }
}
