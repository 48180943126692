@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $titleColor;

  min-height: 20px;

  &.withFile {
    justify-content: left;
  }
}

.typeInfoWithFile {
  margin-bottom: 20px;

  @include mqMaxWidth($screenS) {
    margin-bottom: 10px;
  }
}

.fileWrapper {
  display: flex;

  font-weight: bold;
  color: $titleColor;
}

.filesystemIcon {
  margin-right: 10px;
}

.linkBtn {
  padding: 0;
  border: 0;
  background: none;
  color: #4a76b6;
  cursor: pointer;
}
