@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/helpers/wordWrap.scss";

.fileIcon > i {
  width: 24px;
  height: 24px;
}

.titleWrp {
  color: $titleColor;
  padding: 20px 0;
  width: 100%;
  text-align: center;
  @include break-word;

  @include mqMaxWidth($screenS) {
    padding: 20px 10px;
  }
}

.inputWrp {
  display: flex;
  max-width: 421px;
  min-height: 96px;
  width: 100%;

  @include mqMaxWidth($screenS) {
    min-height: 76px;
  }
}

.fileNameWrp {
  flex-grow: 1;

  > div {
    margin-bottom: 0;
  }

  input {
    border-radius: 3px 0 0 3px;
    height: 36px;
  }
}

.fileExtension {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  margin-bottom: 30px;
  border: 1px solid $secondary2Color;
  box-sizing: border-box;
  border-radius: 0 3px 3px 0;
  color: $secondaryColor;
  background: $defaultBackgroundColor;
  margin-left: -1px;
  height: 36px;
  flex-shrink: 0;
}
