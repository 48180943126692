.childrenWrp {
  display: none;
}

.isOpen + .childrenWrp {
  display: block;
}

.isRoot {
  > .childrenWrp {
    display: block;
  }
}

.nodeItemWrp {
  position: relative;
  user-select: none;
  display: inline-block;
  min-width: 100%;
}
