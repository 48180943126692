@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrapper {
  top: auto;
  bottom: 20px;

 .tooltip {
   display: flex;
   cursor: pointer;

   @include mqMaxWidth($screenS) {
     width: 230px;
   }
 }

  .spinner {
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.2);
  }
}
