@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.copyMoveModal {
  :global(.modalDialog) {
    max-width: 800px;

    @include mqMaxWidth($screenS) {
      max-width: 100%;
    }
  }

  :global(.modalBody) {
    padding: 0;
  }
}

.permissionsWrp {
  margin-bottom: 20px;

  tbody {
    max-height: 120px;

    @include mqMaxWidth($screenS) {
      max-height: none;
    }
  }
}

.contentWrp {
  position: relative;
  padding: 20px 0 0;

  @include mqMaxWidth($screenS) {
    padding-top: 10px;
  }
}

.buttonsWrp {
  display: flex;
}

.isDisabled {
  color: $secondaryColor;

  .nodeFolderIcon {
    margin-right: 6px;
    vertical-align: top;

    > svg {
      fill: $secondary2Color;
    }
  }
}

.bodyPadding {
  padding: 0 20px;

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.agree {
  margin-bottom: 20px;
}

.modalFooter {
  padding-top: 20px;

  @include mqMaxWidth($screenS) {
    padding-top: 0;
  }
}

.modalFooter,
.info {
  .left {
    float: left;
  }

  .right {
    float: right;

    button {
      padding: 0;
      margin: 0;
    }
  }

  @include clearfix;
}

.info {
  margin: 20px 0;

  @include mqMaxWidth($screenS) {
    margin: 20px 0 10px;

    .left {
      float: none;
    }

    .right {
      float: none;
      margin-top: 10px;
    }
  }
}

.changePermissionsIcon {
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;

  @include mqMaxWidth($screenS - 1) {
    margin-right: 0;
  }
}

.changePermissionsButton {
  svg {
    fill: $actionColor;
  }

  &:disabled svg {
    fill: $secondaryColor;
  }
}

.changePermissionsTitle {
  @include mqMaxWidth($screenS - 1) {
    display: none;
  }
}

.capitalize {
  text-transform: capitalize;
}

.tabsWrp {
  padding-top: 0;

  + div {
    max-height: 247px;
    min-width: 247px;
  }
}
