@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.deleteModal {
  :global(.modalDialog) {
    max-width: 800px;
  }

  @include mqMaxWidth($screenS) {
    :global(.modalBody) {
      padding: 10px 0;
    }
  }
}
