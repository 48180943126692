@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contentWrp {
  position: relative;

  @include mqMaxWidth($screenS) {
    padding: 0 10px 10px;
  }
}

.disclaimerWrp {
  padding: 15px;
  background: $disclaimerColor;
}

.buttonsWrp {
  display: flex;
  margin-top: 30px;
}
