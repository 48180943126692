@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.popover {
  max-width: 320px;

  .popoverContent {
    max-width: 320px !important;
    width: 320px;
    padding: 0 0 4px !important;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    cursor: default;
    min-height: 200px;
    position: relative;
    overflow-x: hidden;
  }

  &.isDialInTypeAudio {
    @include mqMaxWidth(480px) {
      .popoverContent {
        width: 264px;
      }

      .header {
        padding-left: 6px;
        padding-right: 6px;
      }

      .row {
        font-size: 13px;

        .dialInCountry {
          width: 148px;
          padding-left: 6px;
        }

        .flagIcon {
          margin-right: 6px;
        }
      }
    }
  }
}

.header {
  padding: 12px;
  line-height: 12px;
  box-sizing: border-box;
  color: $secondaryColor;
  font-size: 11px;
  text-align: left;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.copyIconWrp {
  width: 12px;
  height: 12px;
  cursor: pointer;
  float: right;

  .copyIcon {
    width: 12px;
    height: 12px;
    top: 0;
  }
}

.row {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 25px;
  text-align: left;
  font-size: 14px;
  padding: 5px 0;

  &:nth-child(even) {
    background-color: $secondary5Color;
  }
}

.dialInCountry {
  display: flex;
  flex: 1;
  padding-left: 42px;
  box-sizing: border-box;
}

.flagIcon {
  position: absolute !important;
  left: 10px;
  top: 6px;
  width: 22px;
  line-height: 16px;
}

.dialInCountryName {
  color: $titleColor;
}

.dialInPhone {
  display: flex;
  flex: 1;
  white-space: nowrap;
  color: $baseTextColor;
  padding-left: 5px;
  vertical-align: top;
}

.spinner {
  height: 200px;
}

