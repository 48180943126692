@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.bulkUploadButton {
  cursor: pointer;
  color: $actionColor;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin: 0;

  svg {
    fill: $actionColor;
  }

  &:hover {
    color: $actionColorHover;

    svg {
      fill: $actionColorHover;
    }
  }

  @include mqMaxWidth($screenS) {
    width: 50%;
  }
}

.submitButton {
  @include mqMaxWidth($screenS) {
    width: 50%;
    margin-right: 0 !important;
  }
}

.cancelButton {
  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.submitterBlock {
  display: flex;
}

.submitterLabel {
  margin-right: 10px;
}

.submitterName {
  color: $titleColor;
  font-weight: 700;
}

.submitterCompany {
  color: $secondary3Color;
}

.arrowRight {
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -2px;
}

.contentWrp {
  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.mobileHidden {
  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.footerWrapper {
  margin-top: 30px;
}
