@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.checkboxWrp {
  color: $secondary3Color;
  padding-left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;

  input:checked,
  input:not(:checked) {
    + .checkboxControl:before {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      left: 5px;
      top: 5px;
    }
  }

  &:hover {
    .checkboxControl {
      background: #f3f3f3;
    }
  }

  &.readOnly {
    input:checked + .checkboxControl {
      &:before {
        background-color: $secondaryColor;
      }
    }
  }
}

.checkboxControl {
  margin: 2px auto 0;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
