@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.selectFileWrp {
  display: flex;

  justify-content: space-between;
  flex-wrap: wrap;

  @include mqMaxWidth($screenS) {
    justify-content: flex-start;
  }

  :global(.Select-loading-zone) {
    display: none;
  }
}

.selectInput {
  flex-grow: 1;
  margin-bottom: 0;
  margin-right: 10px;
  text-align: left;

  &.withFile {
    input {
      color: transparent;
    }
  }

  @include mqMaxWidth($screenS) {
    width: 100%;
    margin-bottom: 10px !important;
    margin-right: 0;
  }
}

.filesystemIcon {
  margin-right: 10px;

  i {
    vertical-align: middle;
    margin-top: -3px;
  }
}

.cancelButton {
  margin: 0;
}

.okButton {
  margin: 0;

  &:disabled {
    margin: 0;
    background-color: $btnDefaultColor !important;
    border-color: $btnDefaultColorHover;
  }
}
