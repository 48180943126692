@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.iconButtonWrp {
  margin-right: 0;
  width: auto;
  height: auto;
}

.iconButton {
  width: auto;
  height: auto;
}

.textWithIcon {
  width: 100%;
}

.isFetching:not(.isDisabled),
.isFetching:not(.isDisabled):hover {
  background-color: $actionColor;

  > * {
    color: #fff;
    opacity: 0.5;
  }
}

.dropdownWrapper {
  display: flex;
  position: relative;
}
