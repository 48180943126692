@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.mobileInput {
  border: 1px solid transparent;
}

.mobileInputError {
  border: 1px solid $cancelColor;
}

.popover {
  .popoverContent {
    background-color: $cancelColor !important;
    padding: 8px 12px !important;

    color: #fff !important;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .arrow:after {
    border-bottom-color: $cancelColor !important;
  }
}
