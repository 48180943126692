@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$lowColor: #c0c0c0;
$mediumColor: #ffad0f;
$highColor: #cc4d4d;

.radioGroupWrp {
  margin-bottom: 20px;
}

.radioGroupContainer {
  position: relative;
  display: flex;

  .radioItemWrp {
    cursor: pointer;
    margin-right: 0;
    margin-left: 20px;

    display: flex;
  }

  .radioItemWrp > input + .radioItem {
    padding-left: 0;
    width: 100%;
    text-align: center;

    font-size: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $secondaryColor;
    line-height: 10px;

    &:before {
      position: static;
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 1px solid $secondary2Color;
      background: transparent;

      margin: 0 10px 0 0;
    }

    &:after {
      content: none;
    }
  }

  .radioItemWrp > input:checked + .radioItem {
    color: $baseTextColor;

    &.low:before {
      border-color: $lowColor;
      background-color: $lowColor;
    }

    &.medium:before {
      border-color: $mediumColor;
      background-color: $mediumColor;
    }

    &.high:before {
      border-color: $highColor;
      background-color: $highColor;
    }
  }
}

.radioItemWrp:hover {
  .radioItem.low:before {
    border-color: $lowColor;
  }

  .radioItem.medium:before {
    border-color: $mediumColor;
  }

  .radioItem.high:before {
    border-color: $highColor;
  }

  .radioItem:before {
    border-color: $secondary2Color;
  }

  > input:disabled:not(:checked) + .radioItem.low:before {
    border-color: $secondary2Color;
  }

  > input:disabled:not(:checked) + .radioItem.medium:before {
    border-color: $secondary2Color;
  }

  > input:disabled:not(:checked) + .radioItem.high:before {
    border-color: $secondary2Color;
  }
}

.tooltipContainer {
  display: block;
}
