@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tooltip {
  padding: 5px;
}

.row {
  display: flex;
}

.title {
  text-align: left;
  white-space: nowrap;
  margin-right: 5px;
  color: $secondaryColor;
  min-width: 70px;

  &.wide {
    min-width: 90px;
  }

  &.extraWide {
    min-width: 150px;
  }
}

.text {
  text-align: left;
}
