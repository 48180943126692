.wrapper {
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;
  padding-left: 26px;
}

.checkbox {
  width: 300px;
  padding: 5px 0;

  &:first-of-type {
    padding-top: 0;
  }
}
