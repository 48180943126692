@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";

.verificationModal {
  text-align: center;

  :global(.modalFooter) {
    padding: 0;
  }
  :global(.modalDialog) {
    max-width: 600px;
  }
  :global(.modalBody) {
    padding: 50px;
  }
}

.verificationModalContent {
  text-align: center;
}

.logo {
  i {
    svg {
      fill: $firmColor;
    }
  }
}

.title {
  color: $titleColor;
}

.description {
  line-height: 20px;
}

.footer {
  display: block;
  background: $defaultBackgroundColor;
  color: $actionColor;
  font-size: 14px;
  height: 67px;
  line-height: 67px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: $actionColorHover;
  }
}
