@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.changePermissionsIcon {
  vertical-align: middle;
  margin-top: -5px;
  margin-right: 10px;

  svg {
    fill: $actionColor;
  }

  @include mqMaxWidth($screenS - 1) {
    margin-right: 0;
  }
}

.changePermissionsButton {
  cursor: pointer;
  color: $actionColor;
  line-height: 36px;
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin-right: 0;
  padding: 0;

  &:hover {
    color: $actionColorHover;

    svg {
      fill: $actionColorHover;
    }
  }

  &:disabled {
    margin-right: 0;
    padding: 0;

    svg,
    &:hover svg {
      fill: $secondaryColor;
    }
  }
}

.changePermissionsButtonText {
  @include mqMaxWidth($screenS - 1) {
    display: none;
  }
}
