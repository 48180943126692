@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.select {
  :global(.Select-menu-wrapper) {
    position: absolute;
    margin-top: -8px;
    width: 100%;
    z-index: 4;
  }

  @include mqMaxWidth($screenS) {
    padding: 5px 0;
  }
}
