@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.sectionWrp {
  padding-top: 20px;
}

.buttonsWrp {
  padding: 20px 20px 0;

  @include mqMaxWidth($screenXs) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.submitButton,
.cancelButton {
  float: left;
}

.changePermissions {
  tbody {
    max-height: none;
  }
}
