@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.pathColumn {
  display: flex;
  white-space: nowrap;
  color: $secondaryColor;
  font-size: 11px;
  line-height: 15px;
}

.pathItem,
.pathItemDelimiter {
  display: inline-block;
  vertical-align: middle;
}

.pathItem {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
