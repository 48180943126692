@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.disabled {
  i {
    fill: $secondaryColor;
    pointer-events: none;

    &:hover {
      cursor: default;
      fill: $secondaryColor;
    }
  }
}
