@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

// this class use for hide badge of recaptcha
// must be used together with ui/shared/components/Form/ReCaptcha/ReCaptchaPolicy.tsx
// doc: https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.recaptchaHidden {
  :global(.grecaptcha-badge) {
    visibility: hidden;
  }
}

.recaptchaWrp {
  margin-top: 7px;
  display: inline-block;
}

.recaptchaPolicy {
  color: $secondary3Color;
  text-align: center;
  font-family: $fontFamily;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.12px;

  a {
    color: $actionColor;
  }
}
