@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.emailModal {
  :global(.modalDialog) {
    max-width: 473px;

    @include mqMaxWidth($screenS) {
      max-width: 100%;
    }
  }

  .title {
    padding-top: 20px;
  }
}

.contentWrp {
  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.description {
  padding-bottom: 20px;
}

.support {
  padding: 15px;
  background-color: $backgroundHighlightColor;
}
