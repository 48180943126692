@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.actions {
  display: none;
  line-height: 0;
  margin-top: -16px;
  white-space: nowrap;
}

.actionItem {
  display: inline-block;
  margin-left: 18px;

  i,
  svg {
    fill: $actionColor;
  }

  &:hover {
    i,
    svg {
      fill: $actionColorHover;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  button,
  button:disabled {
    padding: 0;
    line-height: 14px;
    height: 16px;
    width: 16px;
    margin-right: 0;
    border-width: 0;
  }

  button:disabled {
    cursor: default;

    i {
      fill: $secondary2Color;
    }

    &:hover i {
      fill: $secondary2Color;
    }
  }

  @include mqMaxWidth($screenM) {
    margin-left: 7px;
  }
}

table tbody tr {
  .mobileAdditionalOptionsTriggerWrp {
    height: auto;
  }

  &:hover {
    .actionColumnData {
      visibility: hidden;

      @include mqMaxWidth($screenS) {
        visibility: visible;
      }
    }

    .actions {
      display: block;

      @include mqMaxWidth($screenS) {
        display: none;
      }
    }

    .mobileDropdownContent > div {
      @include mqMaxWidth($screenS) {
        display: block;
      }
    }
  }

  &:first-child {
    .mobileAdditionalOptionsTriggerWrp {
      top: 1px;
    }
  }

  &:global(.is-selected) {
    .mobileDropdownContent .mobileDataroomActions {
      display: block;
    }
  }
}

.mobileDataroomActions {
  display: block;
  margin-top: 0;
  background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 50%);

  .actionItem {
    display: block;
    margin: 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;

      .textActionItem,
      .alertTextActionItem {
        margin-bottom: 10px;
      }
    }

    i,
    span {
      display: inline-block;
      vertical-align: middle;
    }

    i {
      margin-right: 10px;
      fill: $actionColor;
    }

    &:hover {
      i {
        fill: $actionColorHover;
      }
    }
  }
}

.mobileAdditionalOptionsTriggerWrp {
  background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 50%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

table tbody tr:hover,
table tbody tr:global(.is-hovered) {
  .mobileAdditionalOptionsTriggerWrp {
    background: linear-gradient(90deg, rgba($secondary5Color, 0) 0%, rgba($secondary5Color, 1) 50%);
  }
}

:global(.is-selected),
:global(.is-selected):hover {
  .mobileAdditionalOptionsTriggerWrp {
    background: linear-gradient(90deg, rgba($backgroundHighlightColor, 0) 0%, rgba($backgroundHighlightColor, 1) 50%);
  }
}

.mobileAdditionalOptionsTrigger {
  display: none;
  cursor: pointer;
  position: static;
  margin-left: 20px;

  @include mqMaxWidth($screenS) {
    display: block;
  }
}

.triggerIcon {
  fill: $actionColor;

  &.isDisabled {
    fill: $secondary2Color;
  }
}

.mobileDropdownContent {
  white-space: nowrap;
  right: 5px;
  top: -2px;
  padding: 10px 0 0;
  text-align: left;
  z-index: 2;
  min-width: 145px;

  > div {
    line-height: normal;

    > div {
      margin-left: 0;
    }
  }
}

.textActionItem,
.alertTextActionItem {
  padding: 10px 20px;

  button {
    width: auto;
  }
}

.textActionItem {
  color: $actionColor;
  font-weight: normal;

  button {
    color: $actionColor;
  }
}

.alertTextActionItem {
  color: $cancelColor;

  button {
    color: $cancelColor;
  }
}

.infoActionItem {
  color: $secondary3Color;
  font-size: 12px;
  line-height: 17px;
  padding: 10px 20px;
  border-top: 1px solid $defaultTableBorderColor;
}

.mobileActionsWrapper {
  z-index: 1;
  background-color: transparent !important;
  width: 50px;
  min-width: 50px;
  right: 0;
  position: sticky !important;
  margin-left: -50px;
  padding: 0 !important;
  height: inherit;
  display: none;
  border-bottom: 0;

  > * {
    height: 100%;
  }

  @include mqMaxWidth($screenS) {
    display: table-cell;
  }
}

th.mobileActionsWrapper {
  display: none;
}

.popoverArrowWrapper {
  display: none;
}
