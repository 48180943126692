@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.modalWrp {
  :global(.modalDialog) {
    max-width: 800px;
    @include mqMaxWidth($screenS) {
      height: 100%;
    }
  }

  :global(.modalBody) {
    @include mqMaxWidth($screenS) {
      padding: 10px 0 0;
    }
  }
}
