@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.troubleFeedbackContainer {
  display: flex;
  align-items: center;
}

.troubleFeedbackTitle {
  font-size: 12px;
  color: $secondary3Color;
}

.toggleIconWrapper {
  margin-left: 5px;
}

.toggleIcon {
  display: flex;
  cursor: pointer;

  svg {
    fill: $secondaryColor;
  }

  &:hover {
    svg {
      fill: $secondaryColorHover;
    }
  }
}

.troubleFeedbackPopoverContentWrapper {
  max-width: 430px !important;
}

.troubleFeedbackPopoverContent {
  padding: 17px 22px;
  .popoverCloseButton {
    width: 12px;
    height: 12px;
    fill: #fff;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
