@import "@dealroadshow/uikit/core/styles/helpers/wordWrap.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.disclaimerModal {
  background-color: #d2d2d2;

  :global(.modalOverlay) {
    background-color: transparent;
  }
}

.noNewLineString {
  display: inline-block;
}

.content {
  @include break-word;

  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.copyDisable {
  user-select: none;
}

.hint {
  color: #333;
}
