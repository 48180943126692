@import "@/Framework/GlobalStyles/global.scss";

// FIN-9423 Previewer
@media print {
  body {
    display: none !important;
  }
}

body {
  background-color: #fff;
}
