.folder {
  fill: #fdca2c;
}

.stagingFolder {
  fill: #ff6b00;
}

.pdf {
  fill: #e73737;
}

.png {
  fill: #cb6dbc;
}

.jpeg {
  fill: #f02dd1;
}

.tiff {
  fill: #ae0c94;
}

.image {
  fill: #8c36e1;
}

.audio {
  fill: #f96e6e;
}

.video {
  fill: #6e7cf9;
}

.doc {
  fill: #2e8ce2;
}

.xls {
  fill: #30af63;
}

.csv {
  fill: #21ceaf;
}

.cdi {
  fill: #00ca39;
}

.ppt {
  fill: #f9701e;
}

.txt {
  fill: #91acc6;
}

.xml {
  fill: #cc4d4d;
}

.archive {
  fill: #b4680f;
}

.url {
  fill: #c0c0c0;
}

.file {
  fill: #c0c0c0;
}


tr:hover,
tr:global(.is-hovered),
:global(.folder-tree-node):hover,
:global(.folder-tree-node-selected) {
  .folder {
    fill: #eeb400;
  }

  .stagingFolder {
    fill: #e05e00;
  }

  .pdf {
    fill: #c02a2a;
  }

  .png {
    fill: #a05194;
  }

  .jpeg {
    fill: #b91fa0;
  }

  .tiff {
    fill: #80066c;
  }

  .image {
    fill: #6927ab;
  }

  .audio {
    fill: #ca5656;
  }

  .video {
    fill: #535ec1;
  }

  .doc {
    fill: #2675bd;
  }

  .xls {
    fill: #24834a;
  }

  .csv {
    fill: #16967f;
  }

  .cdi {
    fill: #00a42e;
  }

  .ppt {
    fill: #c75611;
  }

  .txt {
    fill: #6c869e;
  }

  .xml {
    fill: #93961e;
  }

  .archive {
    fill: #8e530d;
  }

  .url {
    fill: #888;
  }

  .file {
    fill: #888;
  }
}
