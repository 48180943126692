@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.footerWrp {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 46px;
  border-top: 1px solid #f3f3f3;

  @include mqMaxWidth($screenS) {
    border-top-width: 0;
    height: auto;
    min-height: auto;
    position: static;
    flex-shrink: 1;
    margin-top: 10px;

    .footer {
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      padding: 0;
      min-width: 0;
      text-align: center;
      position: relative;
    }

    .leftAlignedIcon,
    .rightAlignedIcon {
      svg {
        position: static;
      }
      img {
        width: auto;
        height: 16px;
        max-width: none;
      }
    }

    .leftAlignedIcon {
      width: 100%;
      height: 16px;
      margin-bottom: 20px;
      display: block;

      &:first-child {
        margin-left: 0;
      }
    }

    .rightAlignedIcon {
      width: 32px;
      height: auto;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 15px;

      &:before {
        content: '';
        display: inline-block;
        border-top: 1px solid $secondary2Color !important;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 46px;
      }
    }
  }
}

.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  min-width: 275px;
  height: 100%;
}

.leftAlignedIcon {
  position: relative;
  margin-right: 10px;
  width: 26px;
  height: 18px;
  display: inline-block;

  &:first-child {
    margin-left: -9px;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: $actionColor;
  }
}

.leftAlignedAction {
  display: inline-block;
  padding: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 27px;

  &:hover {
    background-color: #eaeaea;
    border-radius: 2px;
    svg {
      fill: $actionColorHover;
    }
  }
}

.rightAlignedIcon {
  display: flex;
  width: 90px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.isCollapsed {
  border-top-width: 0;
  height: 195px;
  min-height: 195px;
  position: static;
  margin-top: 20px;

  .footer {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    padding: 0;
    min-width: 0;
    text-align: center;
  }

  .leftAlignedIcon,
  .rightAlignedIcon {
    svg {
      position: static;
    }
    img {
      width: auto;
      height: 16px;
      max-width: none;
    }
  }

  .leftAlignedIcon {
    width: 100%;
    height: 16px;
    margin-bottom: 20px;
    display: block;

    &:first-child {
      margin-left: 0;
    }
  }

  .rightAlignedIcon {
    width: 32px;
    height: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 15px;

    &:before {
      content: '';
      display: inline-block;
      border-top: 1px solid #f3f3f3;
      width: 100%;
      position: absolute;
      left: 0;
      margin-top: -16px;
    }
  }
}
