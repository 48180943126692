@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.actions {
  display: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 19.27%);
  align-items: center;
  justify-content: flex-end;
}

.failure .actions {
  background: transparent;
}

.toolbarAction {
  display: inline-block;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 15px;
  }

  svg {
    fill: #4a76b6;

    &:hover {
      fill: $actionColorHover;
    }
  }
}

.toolbarWrp {
  position: relative;
}

.dataTableContainer table tbody tr {
  td i {
    vertical-align: top;
  }

  .actionsColumnData {
    color: $secondaryColor;
  }

  &:hover {
    .actionsColumnData {
      display: none;
    }

    .actions {
      display: block;
    }
  }

  &.success {
    background-color: #f2fff2;
  }

  &.failure {
    background-color: #ffeded;
  }
}
