@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.progressBarWrp {
  padding: 15px 20px 0;

  @include mqMaxWidth($screenS) {
    padding: 15px 10px 0;
  }
}

.progressBar {
  border: 0;
  background-image: none;
  filter: none;
  box-shadow: none;
  height: 16px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 0 !important;
}

.filledWithoutAnimation,
.filledWithoutAnimation > div {
  transition: none !important;
}

.filled,
.filled > div {
  height: 18px;
  background-size: 40px 40px;
  float: left;
  width: 0;

  color: #fff;
  text-align: center;
  background-color: #f5f5f5;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
  border-radius: 0 !important;

  .fileUploading {
    width: 0;
    background-image: linear-gradient(45deg, rgba(51, 122, 183, 0.6) 25%, rgba(51, 122, 183, 0.6) 25%, rgba(51, 122, 183, 0.6) 50%, rgba(51, 122, 183, 1) 50%, rgba(51, 122, 183, 1) 75%, rgba(51, 122, 183, 0.6) 75%, rgba(51, 122, 183, 0.6));
    background-color: rgba(81, 142, 194, 0.8);
  }

  .warning {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-color: #e05656;
  }
}

.status {
  margin-bottom: 5px;
  font-size: 11px;
  color: $successColor;
}

.error {
  color: $cancelColor;
}
